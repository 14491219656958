import { ProductConfigurableAttributes as AdvoxProductConfigurableAttributes } from 'AdvoxComponent/ProductConfigurableAttributes/ProductConfigurableAttributes.component';
import ProductAttributeValue from 'Component/ProductAttributeValue';

/** @namespace Pwabb/Component/ProductConfigurableAttributes/Component */
export class ProductConfigurableAttributes extends AdvoxProductConfigurableAttributes {
    renderSwatch(option, isUnselected) {
        const { handleShakeAnimationEnd } = this.props;
        const { attribute_values, attribute_code, attribute_options } = option;

        const defaultSortedAttributeValues = attribute_values?.sort(
            (a, b) => attribute_options[a]?.index - attribute_options[b]?.index
        );

        return (
            <div
                block="ProductConfigurableAttributes"
                elem="SwatchList"
                mods={{ isUnselected }}
                key={attribute_code}
                onAnimationEnd={handleShakeAnimationEnd}
            >
                {defaultSortedAttributeValues.map((attribute_value) =>
                    this.renderConfigurableAttributeValue({ ...option, attribute_value })
                )}
            </div>
        );
    }

    renderConfigurableAttributes() {
        const {
            configurable_options,
            isExpandable,
            inStock,
            handleShakeAnimationEnd,
            addToCartTriggeredWithError,
            parameters,
        } = this.props;

        return Object.values(configurable_options).map((option) => {
            const { attribute_code, attribute_label, attribute_options, attribute_id } = option;
            const isUnselected = addToCartTriggeredWithError ? !parameters[attribute_code] : null;
            const [{ swatch_data }] = attribute_options ? Object.values(attribute_options) : [{}];
            const isSwatch = !!swatch_data;

            // render content without heading and subheading
            if (!isExpandable) {
                return isSwatch ? this.renderSwatch(option) : this.renderDropdown(option);
            }

            if (!inStock && !isSwatch) {
                return null;
            }

            return (
                <div key={attribute_id}>
                    <p
                        block="ProductConfigurableAttributes"
                        elem="Title"
                        mods={{ isUnselected }}
                        onAnimationEnd={handleShakeAnimationEnd}
                    >
                        {attribute_label}
                    </p>
                    {isSwatch ? this.renderSwatch(option, isUnselected) : this.renderDropdown(option, isUnselected)}
                </div>
            );
        });
    }

    renderConfigurableAttributeValue(attribute) {
        // modifed, so that it doest take 'inStock' into account
        const {
            getIsConfigurableAttributeAvailable,
            handleOptionClick,
            getLink,
            isSelected,
            showProductAttributeAsLink,
        } = this.props;

        const { attribute_value } = attribute;

        const isConfAttrAvailable = getIsConfigurableAttributeAvailable(attribute);

        return (
            <ProductAttributeValue
                key={attribute_value}
                attribute={attribute}
                isSelected={isSelected(attribute)}
                isAvailable={isConfAttrAvailable}
                onClick={handleOptionClick}
                getLink={getLink}
                showProductAttributeAsLink={showProductAttributeAsLink}
            />
        );
    }

    render() {
        const { isReady, mix } = this.props;

        return (
            <div block="ProductConfigurableAttributes" mods={{ isLoading: !isReady }} mix={mix}>
                {isReady ? this.renderConfigurableAttributes() : this.renderPlaceholders()}
            </div>
        );
    }
}

export default ProductConfigurableAttributes;
