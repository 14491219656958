import { getFormattedRegion } from 'Util/Address';

export * from 'AdvoxComponent/MyAccountAddressTable/MyAccountAddressTable.table';

/** @namespace Pwabb/Component/MyAccountAddressTable/Table/getAddressTablePairArray */
export const getAddressTablePairArray = (props) => {
    const { address, countries, showAdditionalFields } = props;
    const regionData = getFormattedRegion(address, countries);
    const countryData = Object.keys(regionData)?.length ? regionData : { country: address?.country_id };

    const additionalFields = [
        {
            key: 'telephone',
            label: __('Phone number'),
            source: address,
        },
    ];

    return [
        {
            key: 'street',
            label: __('Street and number'),
            source: address,
        },
        {
            key: 'postcode',
            label: __('Postal code'),
            source: address,
        },
        {
            key: 'city',
            label: __('Place'),
            source: address,
        },
        {
            key: 'country',
            label: __('Country'),
            source: countryData,
        },
        ...(showAdditionalFields ? additionalFields : []),
    ];
};
