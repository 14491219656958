import {
    B2B,
    DEFAULT_B2B_STOCK_CODE,
    DEFAULT_OUTSOURCING_STOCK_CODE,
    OUTSOURCING,
} from 'Component/ProductActions/ProductActions.config';
import {
    getBundleOptions,
    getIndexedConfigurableOptions,
    getIndexedCustomOptions,
    getIndexedReviews,
    getIndexedSingleVariant,
    getIndexedVariants,
} from 'SourceUtil/Product/Product';

export * from 'SourceUtil/Product/Product';

/** @namespace Pwabb/Util/Product/getAttributesWithValues */
export const getAttributesWithValues = (product) => {
    const { attributes = {}, parameters = {} } = product;

    return Object.entries(attributes).reduce((acc, [key, val]) => {
        const { attribute_value } = val;

        if (attribute_value) {
            return { ...acc, [key]: val };
        }

        const valueIndexFromParameter = parameters[key];

        if (valueIndexFromParameter) {
            return { ...acc, [key]: { ...val, attribute_value: valueIndexFromParameter } };
        }

        return acc;
    }, {});
};

/** @namespace Pwabb/Util/Product/filterConfigurableOptions */
export const filterConfigurableOptions = (options, variants) =>
    Object.values(options).reduce((acc, option) => {
        const { attribute_values, attribute_code } = option;

        // show option if it exist as variant for configurable product
        const filteredOptions = attribute_values?.reduce((acc, value) => {
            const isVariantExist = variants.find(({ attributes }) => {
                const { attribute_value: foundValue } = attributes[attribute_code] || {};

                return value === foundValue;
            });

            if (isVariantExist) {
                acc.push(value);
            }

            return acc;
        }, []);

        return {
            ...acc,
            [attribute_code]: {
                ...option,
                attribute_values: filteredOptions,
            },
        };
    }, {});

/** @namespace Pwabb/Util/Product/displaySalableQtyMsg */
export const displaySalableQtyMsg = (salableQty = 0) => {
    if (salableQty < 1) {
        return __('0 pcs.');
    }

    if (salableQty <= 5) {
        return (
            <>
                {salableQty} {__('pcs.')}
            </>
        );
    }

    if (salableQty > 5) {
        return __('More than 5 pcs.');
    }

    return salableQty;
};

/** @namespace Pwabb/Util/Product/calculateProductAvailability */
export const calculateProductAvailability = (source = '', product, activeProduct) => {
    const { stock_sources = [], variants = [] } = product;
    const { stock_sources: stock_variant_sources = [] } = activeProduct;
    const stockCode = source === OUTSOURCING ? DEFAULT_OUTSOURCING_STOCK_CODE : DEFAULT_B2B_STOCK_CODE;

    const getStockQuantity = (sources, code) => {
        if (code === DEFAULT_B2B_STOCK_CODE && sources[0]?.source_code !== B2B) {
            return 0;
        }

        if (sources[0]?.source_code === B2B) {
            return sources[code]?.quantity || 0;
        }

        return sources[0]?.quantity || 0;
    };

    if (variants.length) {
        if (!stock_variant_sources.length) {
            if (variants.length === 1) {
                return getStockQuantity(variants[0].stock_sources, stockCode);
            }

            return variants.reduce((total, variant) => {
                if (variant.stock_sources[stockCode]?.source_code === source) {
                    return total + getStockQuantity(variant.stock_sources, stockCode);
                }

                return total;
            }, 0);
        }

        return getStockQuantity(stock_variant_sources, stockCode);
    }

    return getStockQuantity(stock_sources, stockCode);
};

/** @namespace Pwabb/Util/Product/getIndexedAttributeOption */
export const getIndexedAttributeOption = (option, index) => {
    const { swatch_data: defaultSwatchData } = option;

    if (!defaultSwatchData) {
        return option;
    }

    const { type } = defaultSwatchData;
    const swatch_data = type ? defaultSwatchData : null;

    return {
        ...option,
        swatch_data,
        index,
    };
};

/** @namespace Pwabb/Util/Product/getIndexedAttributes */
export const getIndexedAttributes = (attributes) =>
    attributes.reduce((indexedAttributes, attribute) => {
        const { attribute_code, attribute_options = [] } = attribute;
        return {
            ...indexedAttributes,
            [attribute_code]: {
                ...attribute,
                attribute_options: attribute_options.reduce((acc, option, index) => {
                    const { value } = option;

                    return {
                        ...acc,
                        [value]: getIndexedAttributeOption(option, index),
                    };
                }, {}),
            },
        };
    }, {});

/** @namespace Pwabb/Util/Product/getIndexedProduct */
export const getIndexedProduct = (product, itemSku) => {
    const {
        variants: initialVariants = [],
        configurable_options: initialConfigurableOptions = [],
        attributes: initialAttributes = [],
        options: initialOptions = [],
        rating_summary,
        review_count,
        reviews: initialReviews,
        items = [],
        bundle_options = [],
    } = product;

    const attributes = getIndexedAttributes(initialAttributes || []);
    const reviews = getIndexedReviews(initialReviews);

    const updatedProduct = {
        ...product,
        configurable_options: getIndexedConfigurableOptions(initialConfigurableOptions, attributes),
        variants: itemSku ? getIndexedSingleVariant(initialVariants, itemSku) : getIndexedVariants(initialVariants),
        options: getIndexedCustomOptions(initialOptions || []),
        attributes,
        // Magento 2.4.1 review endpoint compatibility
        reviews,
        review_summary: {
            rating_summary,
            review_count,
        },
    };

    if (bundle_options.length) {
        updatedProduct.items = getBundleOptions(bundle_options, items);
    }

    return updatedProduct;
};
