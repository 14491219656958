import { ProductConfigurableAttributesContainer as AdvoxProductConfigurableAttributesContainer } from 'AdvoxComponent/ProductConfigurableAttributes/ProductConfigurableAttributes.container';
import { STOCK_TYPE } from 'Component/Product/Stock.config';

/** @namespace Pwabb/Component/ProductConfigurableAttributes/Container */
export class ProductConfigurableAttributesContainer extends AdvoxProductConfigurableAttributesContainer {
    getIsConfigurableAttributeAvailable({ attribute_code, attribute_value }) {
        const { inStock, parameters, variants } = this.props;

        // skip out of stock check, if variants data has not been provided
        if (!variants) {
            return true;
        }

        const isAttributeSelected = Object.hasOwnProperty.call(parameters, attribute_code);

        // If value matches current attribute_value, option should be enabled
        // OVERRIDE NOTE: Added inStock check
        if (isAttributeSelected && parameters[attribute_code] === attribute_value && inStock) {
            return true;
        }

        const parameterPairs = Object.entries(parameters);

        const selectedAttributes = isAttributeSelected
            ? // Need to exclude itself, otherwise different attribute_values of the same attribute_code will always be disabled
              parameterPairs.filter(([key]) => key !== attribute_code)
            : parameterPairs;

        return variants.some(({ stock_status, attributes }) => {
            const { attribute_value: foundValue } = attributes[attribute_code] || {};

            return (
                stock_status === STOCK_TYPE.IN_STOCK &&
                // Variant must have currently checked attribute_code and attribute_value
                foundValue === attribute_value &&
                // Variant must have all currently selected attributes
                selectedAttributes.every(([key, value]) => attributes[key].attribute_value === value)
            );
        });
    }

    isSelected({ attribute_code, attribute_value }) {
        const { parameters = {} } = this.props;
        const parameter = parameters[attribute_code];

        if (parameter === undefined) {
            return false;
        }

        return parameter === attribute_value;
    }
}

export default ProductConfigurableAttributesContainer;
